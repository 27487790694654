import { io } from 'socket.io-client';

export const socket = io(process.env.REACT_APP_API_HOST, {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: 10, // Try to reconnect 10 times before failing
  reconnectionDelay: 2000, // Time between reconnect attempts (2 sec)
  timeout: 10000, // Connection timeout (10 sec)
  transports: ['websocket'], // Use WebSocket transport instead of polling
  withCredentials: true, // Allow cross-origin credentials
  forceNew: true, // Force new connection on reload
});

socket.on('connect', () => {
  console.log('Socket connected:', socket.id);
});

// Handle disconnections
socket.on('disconnect', (reason) => {
  console.warn('Socket disconnected:', reason);
});

// Log reconnection attempts
socket.on('reconnect_attempt', (attempt) => {
  console.log(`Socket reconnect attempt #${attempt}`);
});

// Log successful reconnection
socket.on('reconnect', (attempt) => {
  console.log(`Socket reconnected successfully after ${attempt} attempts`);
});
