import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Modal,
  Box,
  Typography,
  Tooltip,
  TablePagination,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  RemoveRedEye,
  Create,
} from '@mui/icons-material';
import EventBus from '../common/EventBus';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import { isUserAdminOrCareCoordinator } from '../common/utils/RoleUtils.js';
import styles from '../styles/Reminders.module.css';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../actions/loading';
import {
  appointmentService,
  complianceService,
  userService,
} from '../services';
import useSocketStepStatus from '../hooks/useSocketStepStatus.js';
import { MultiSlotModal, SocketIoModal, CancellationFormModal } from './Modals';
import { CancelSurveyDialog } from './Dialogs';
import { socket } from '../common/socketIO/sockets.js';
import {
  Socket_IO_Appointment_Cancel_Event_Initial_Steps,
  Socket_IO_Appointment_Update_Event_Initial_Steps,
} from '../common/socketIO/socketIOEvents.js';

/**
 * The below function is actually there to check/compare
 * which slot has been clicked. Because, there are multiple
 * Slots and we need to find out the exact slot which was clicked. So,
 * that we can send the provider_availability_id
 */
function findTheClickedSlot(appointmentStartTime, appointmentEndTime, slots) {
  // Convert appointment times to Date objects
  const appointmentStart = new Date(appointmentStartTime);
  const appointmentEnd = new Date(appointmentEndTime);

  // Find and return the matched slot
  return (
    slots.find((slot) => {
      const slotStart = new Date(slot.start_date_time);
      const slotEnd = new Date(slot.end_date_time);
      return (
        slotStart.getTime() === appointmentStart.getTime() &&
        slotEnd.getTime() === appointmentEnd.getTime()
      );
    }) || null
  ); // Return null if no match is found
}

export default function Reminders() {
  /**
   * Below is the state for managing the socketIOSteps.
   * As for delete and update appointments. The steps
   * will be updated from here.
   */
  const [currentSocketIOSteps, setCurrentSocketIOSteps] = useState([]);

  /**
   * Below is the custom Hook for maintaining the socket io
   * Both the initial Steps and Socket is getting passed
   */
  const steps = useSocketStepStatus(currentSocketIOSteps, socket);

  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showFinalModal, setShowFinalModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [selectedSlots, setSelectedSlots] = useState([]);
  // eslint-disable-next-line
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [careCoordinators, setCareCoordinators] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [adminOrCareCoordinators, setAdminOrCareCoordinators] = useState([]);
  const [showSocketIOModal, setShowSocketIOModal] = React.useState(false);
  const [selectedCareCoordinator, setSelectedCareCoordinator] = React.useState(
    []
  );
  const [reason, setReason] = React.useState('');
  const [, setIsCancel] = React.useState(false);
  const [sortOrder, setSortOrder] = React.useState(true);
  /**
   * The below two states for selectingStartDate
   * and selectingEndDate for Rescheduling.
   */
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());

  const [selectedReminder, setSelectedReminder] = React.useState();
  const [selectedAppointmentId, setSelectedAppointmentId] = React.useState();
  const [reminders, setReminders] = React.useState([]);
  const [radioGroupValue, setRadioGroupValue] = React.useState([]);
  const [remindersMasterList, setRemindersMasterList] = React.useState([]);
  const [reminderPreview, setReminderPreview] = React.useState();
  const [selectedMainFilter, setSelectedMainFilter] = React.useState('1');
  const [selectedSubFilter, setSelectedSubFilter] = React.useState('3');
  const [cancelOpenSlot, setCancelOpenSlot] = React.useState(false);
  const [selectedProviderAvailabilityId, setSelectedProviderAvailabilityId] =
    useState(null);
  const [openEmailReminder, setOpenEmailReminder] = useState(false);
  const [openCancelSurvey, setOpenCancelSurvey] = useState(false);
  /**
   * Below is the state for managing which operation is getting performed.
   * Like Cancelling or updating appointment. This is used to show modal
   * after SocketIO modal disappears
   */
  const [operationGettingPerformed, setOperationGettingPerformed] = useState();

  /**
   * Below is the state for manging if we received any response from the BE.
   * For events like Cancelling or updating appointment. This is used to enable
   * disable the close button.
   */
  const [operationResponseReceived, setOperationResponseReceived] =
    useState(false);

  const isMultiSlotAppointment = (appointment) => {
    return appointment?.multi_slot_series === true;
  };

  const handleMainFilterChange = (event) => {
    setSelectedMainFilter(event.target.value);
    if (event.target.value === '1') {
      setSelectedSubFilter('3');
    } else if (event.target.value === '2') {
      setSelectedSubFilter('7');
    }
  };

  const handleOpenEmailReminder = () => {
    setOpenEmailReminder(true);
  };

  const handleCloseEmailReminder = () => {
    setOpenEmailReminder(false);
  };

  const handleOpenCancelSurvey = (providerAvailabilityId) => {
    // Set the selected provider availability ID
    setSelectedProviderAvailabilityId(providerAvailabilityId);

    // Then open the dialog
    setOpenCancelSurvey(true);
  };

  const handleCloseCancelSurvey = () => {
    setOpenCancelSurvey(false);
  };

  // Below State for opening ReScheduling Modal
  const [openSettingModal, setOpenSettingModal] = React.useState(false);

  const handleCombinedClick = (providerAvailabilityId) => {
    handleSelectProviderAvailability(providerAvailabilityId);
    handleCancelSurvey();
  };

  const handleSelectProviderAvailability = (id) => {
    setSelectedProviderAvailabilityId(id);
  };

  const handleCancelSurvey = () => {
    if (selectedProviderAvailabilityId) {
      showLoading(); // Show loading indicator
      complianceService
        .cancelSurvey(selectedProviderAvailabilityId) // Assuming this is already a number
        .then((response) => {
          // Handle success
          hideLoading(); // Hide loading indicator

          console.log(response.data.message);
          handleCloseCancelSurvey(); // Assuming this is your function to close the modal
          // Further UI updates or state changes
          // Find the item in reminders array and update it
          const updatedReminders = reminders.map((reminder) => {
            if (
              reminder.provider_availability_id ===
              selectedProviderAvailabilityId
            ) {
              return { ...reminder, survey_cancelled: 1 }; // Update the survey_cancelled property
            }
            return reminder;
          });

          // Set the updated reminders array to state
          setReminders(updatedReminders);

          console.log(response.data.message);
        })
        .catch((error) => {
          // Handle errors
          hideLoading(); // Hide loading indicator
          console.error(
            'Error cancelling survey:',
            error.response?.data?.message || error.message
          );
        });
    } else {
      console.error('No provider availability selected');
    }
  };

  const handleCloseSettingModal = () => setOpenSettingModal(false);
  const handleOpenSettingModal = () => setOpenSettingModal(true);
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  const sortReminders = (remindersArray) => {
    const currentDate = moment();
    return remindersArray.sort((a, b) => {
      const aDateTime = moment(
        `${a.appointment_date} ${a.appointment_start_time}`,
        'YYYY-MM-DD HH:mm:ss'
      );
      const bDateTime = moment(
        `${b.appointment_date} ${b.appointment_start_time}`,
        'YYYY-MM-DD HH:mm:ss'
      );
      const aDiff = Math.abs(currentDate.diff(aDateTime));
      const bDiff = Math.abs(currentDate.diff(bDateTime));
      return aDiff - bDiff;
    });
  };

  useEffect(() => {
    if (selectedSlots.length === 1) {
      setSelectedStartDate(
        moment(selectedSlots[0]?.start_date_time).format('YYYY-MM-DD HH:mm:ss')
      );
      setSelectedEndDate(
        moment(selectedSlots[0]?.end_date_time).format('YYYY-MM-DD HH:mm:ss')
      );

      // Updating the selected Reminder
      setSelectedReminder({
        ...selectedReminder,
        appointment_start_time: moment(
          selectedSlots[0]?.start_date_time
        ).format('YYYY-MM-DD HH:mm:ss'),
        appointment_end_time: moment(selectedSlots[0]?.end_date_time).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        slots: selectedReminder.slots.map((slot, index) =>
          index === 0
            ? {
                ...slot,
                start_date_time: moment(
                  selectedSlots[0]?.start_date_time
                ).format('YYYY-MM-DD HH:mm:ss'),
                end_date_time: moment(selectedSlots[0]?.end_date_time).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
              }
            : slot
        ),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlots]);

  useEffect(() => {
    userService.getAllUsers().then(
      (response) => {
        let allUsers = response?.data?.users;
        allUsers = allUsers.filter((allUsersObj) => allUsersObj.is_active);

        const adminsAndCareCoordinators = allUsers.filter((user) =>
          [3, 6].includes(user.roleId)
        );
        const onlyCareCoordinators = allUsers.filter(
          (user) => user.roleId === 6
        );

        setAdminOrCareCoordinators(adminsAndCareCoordinators);
        setCareCoordinators(onlyCareCoordinators);
      },
      (error) => {
        console.log(
          'Error fetching users:',
          error.response?.data?.message || error.message || error.toString()
        );
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, []);

  useEffect(() => {
    // Show loading indicator when data fetching starts
    showLoading();

    // Fetch all users
    userService.getAllUsers().then(
      (userResponse) => {
        const allUsersData = userResponse?.data?.users ?? [];
        // Filter users to get admins or care coordinators
        const usersArr = allUsersData.filter(isUserAdminOrCareCoordinator);
        setAdminOrCareCoordinators(usersArr);

        // After fetching users, fetch appointments
        appointmentService
          .getAppointments()
          .then(
            (appointmentResponse) => {
              console.log('Raw API Response:', appointmentResponse.data); // Log raw API response
              let reminderArr = appointmentResponse?.data?.reminders ?? [];

              reminderArr = reminderArr.map((obj) => {
                if (obj) {
                  // Find the provider information
                  const userObjFound = allUsersData.find(
                    (userObj) => userObj.id === obj.provider_id
                  );

                  // Add provider information
                  obj.provider = userObjFound || {
                    id: '9', // Default ID
                    username: 'Levi Y. Breuer', // Default username
                    email: 'default@provider.com', // Default email or any other field you need
                    // Add other necessary fields that your application might expect
                  };

                  // Ensure slots are parsed as an array
                  obj.slots = Array.isArray(obj.slots) ? obj.slots : [];

                  // Find the earliest future slot with status = 'booked'
                  const now = moment();
                  obj.earliestFutureSlot = obj.slots
                    .filter(
                      (slot) =>
                        slot.status === 'booked' &&
                        moment(slot.start_date_time).isAfter(now)
                    )
                    .sort((a, b) =>
                      moment(a.start_date_time).diff(moment(b.start_date_time))
                    )[0]; // Get the earliest slot

                  // Check if it's a multi-slot series
                  obj.multi_slot_series =
                    obj.multi_slot_series === 1 ||
                    obj.multi_slot_series === true;

                  return obj;
                } else {
                  return null; // Handle undefined or null reminder objects
                }
              });

              // Remove any null values from the array
              reminderArr = reminderArr.filter((item) => item !== null);

              // Sort the reminders before setting the state
              const sortedReminders = sortReminders(reminderArr);
              setReminders(sortedReminders);
              setRemindersMasterList(sortedReminders);
              setRadioGroupValue(1);
            },
            (appointmentError) => {
              const errorContent =
                (appointmentError.response && appointmentError.response.data) ||
                appointmentError.message ||
                appointmentError.toString();
              console.error('Error fetching appointments:', errorContent);
            }
          )
          .finally(() => {
            // Hide loading indicator after appointments are fetched
            hideLoading();
          });
      },
      (userError) => {
        const errorContent =
          (userError.response && userError.response.data) ||
          userError.message ||
          userError.toString();
        console.error('Error fetching users:', errorContent);
        // Hide loading indicator if fetching users fails
        hideLoading();
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const onTagsChange = (event, values) => {
    setSelectedCareCoordinator(values?.id);
  };

  useEffect(() => {
    let updatedReminders = [...remindersMasterList];

    const cur = moment();

    if (selectedMainFilter === '1') {
      // Future Appointments
      updatedReminders = remindersMasterList.filter((reminder) => {
        let apptDate;
        if (reminder.appointment_start_time) {
          apptDate = moment(reminder.appointment_start_time);
        } else if (reminder.slots && reminder.slots.length > 0) {
          apptDate = moment(reminder.slots[0].start_date_time);
        } else {
          // Include reminders without date information
          console.log('Reminder without date information:', reminder);
          return true;
        }

        const isFutureAppointment = apptDate.isAfter(cur);
        // Log details within the scope where these variables are available

        // Return whether it's a future appointment
        return isFutureAppointment;
      });

      if (selectedSubFilter === '3' && selectedMainFilter === '1') {
        // Pending email status
        updatedReminders = updatedReminders.filter((reminder) => {
          // If it's a multi-slot appointment, check all the slots
          if (reminder.slots && reminder.slots.length > 0) {
            return reminder.slots.some(
              (slot) => slot.email_status === 'pending'
            );
          }
          // Otherwise, check the reminder itself if single-slot
          return reminder?.email_status === 'pending';
        });
      } else if (selectedSubFilter === '4' && selectedMainFilter === '1') {
        // Sent email status
        updatedReminders = updatedReminders.filter((reminder) => {
          if (reminder.slots && reminder.slots.length > 0) {
            return reminder.slots.some((slot) => slot.email_status === 'Sent');
          }
          return reminder?.email_status === 'Sent';
        });
      } else if (selectedSubFilter === '5' && selectedMainFilter === '1') {
        // Cancelled email status
        updatedReminders = updatedReminders.filter((reminder) => {
          if (reminder.slots && reminder.slots.length > 0) {
            return reminder.slots.some(
              (slot) => slot.email_status === 'cancelled'
            );
          }
          return reminder?.email_status === 'cancelled';
        });
      }
    } else if (selectedMainFilter === '2') {
      // Past Appointments
      updatedReminders = remindersMasterList.filter((reminder) => {
        let apptDate;
        if (reminder.appointment_start_time) {
          apptDate = moment(reminder.appointment_start_time);
        } else if (reminder.slots && reminder.slots.length > 0) {
          apptDate = moment(reminder.slots[0].start_date_time);
        } else {
          // Include reminders without date information
          return true;
        }
        return apptDate.isBefore(cur);
      });
      console.log('Past appointments after date filter: ', updatedReminders);
      if (selectedSubFilter === '7' && selectedMainFilter === '2') {
        // Sent email status for past appointments
        updatedReminders = updatedReminders.filter((reminder) => {
          if (reminder.slots && reminder.slots.length > 0) {
            return reminder.slots.some((slot) => slot.email_status === 'Sent');
          }
          return reminder?.email_status === 'Sent';
        });
      } else if (selectedSubFilter === '8' && selectedMainFilter === '2') {
        // Cancelled status for past appointments
        updatedReminders = updatedReminders.filter((reminder) => {
          return reminder?.status === 'cancelled';
        });
      } // No need for an else if for '9' (All) as it doesn't require any additional filtering.
    }

    updatedReminders = sortReminders(updatedReminders);

    setReminders(updatedReminders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    radioGroupValue,
    remindersMasterList,
    selectedMainFilter,
    selectedSubFilter,
  ]);

  // The below useEffect is for disabling the button and button will be enabled only when user have filled in the form

  useEffect(() => {
    // Check if selectedCareCoordinator has a value (not an array) and reason is truthy
    if (
      !Array.isArray(selectedCareCoordinator) &&
      selectedCareCoordinator &&
      reason
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [selectedCareCoordinator, reason]);

  /*
  The below useEffect for showing the SocketIO Model.
  If we don't have any current steps then simply 
  hide it.
  */
  useEffect(() => {
    if (currentSocketIOSteps.length > 0) {
      setShowSocketIOModal(true);
    } else {
      setShowSocketIOModal(false);
    }
  }, [currentSocketIOSteps]);

  const handleSearch = (event) => {
    try {
      const keyword = event.target.value;
      let updatedReminders = remindersMasterList.filter((reminder) => {
        return reminder?.last_name
          ?.toLowerCase()
          .includes(keyword.toLowerCase());
      });
      setReminders(updatedReminders);
    } catch (error) {
      enqueueSnackbar('Something Went Wrong', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const handleEyeClick = (event, row, slotId) => {
    handleOpenEmailReminder();
    setReminderPreview('Please wait...');
    appointmentService
      // Passing appointment_id and provider_availabilities_id to find specific Availability
      .getAppointmentPreview(
        row.appointment_id,
        row.provider_availabilities_id,
        slotId
      )
      .then(
        (response) => {
          setReminderPreview(response?.data?.reminderDetail?.htmlBody ?? '');
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log('_content:', _content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      );
  };

  // The below function for calling the BE endPoint for updating Reminder
  const handleUpdateReminder = async () => {
    try {
      // Setting the operation getting performed
      setOperationGettingPerformed('updateAppointment');

      // Open the modal that shows real-time updates
      setCurrentSocketIOSteps([
        ...Socket_IO_Appointment_Update_Event_Initial_Steps,
      ]);

      const isMultiSlot = isMultiSlotAppointment(selectedReminder); // Determine if it's a multi-slot appointment

      let updateData = {
        reminder: selectedReminder,
        appointment_id: selectedAppointmentId,
        updated_by: selectedCareCoordinator, // Who is making the update
        reason: reason, // Reason for cancellation
      };

      if (isMultiSlot) {
        // Multi-slot appointments
        updateData.start_date_times = selectedSlots.map((slot) =>
          moment(slot.start_date_time).format('YYYY-MM-DD HH:mm:ss')
        );
        updateData.end_date_times = selectedSlots.map((slot) =>
          moment(slot.end_date_time).format('YYYY-MM-DD HH:mm:ss')
        );
        updateData.slot_ids = selectedSlots.map((slot) => slot.slot_id);
      } else {
        // Single-slot appointments
        updateData.start_date_time = moment(selectedStartDate).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        updateData.end_date_time = moment(selectedEndDate).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        updateData.provider_availability_slots_id = selectedSlotId;
      }

      // Log the updateData to check structure and data being sent
      console.log('Update Data:', updateData);

      await appointmentService.updateAppointment(updateData);

      enqueueSnackbar('Appointment successfully updated', {
        variant: 'success',
      });
      // Setting that we have received response from BE
      setOperationResponseReceived(true);
    } catch (error) {
      console.error(
        'Error updating appointment:',
        error.response?.data || error.message
      );
      if (error.response?.data) {
        enqueueSnackbar(
          `Failed to update appointment: ${error.response.data.message}`,
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(
          'Failed to update appointment due to an unknown error',
          { variant: 'error' }
        );
      }
      // Setting that we have received response from BE
      setOperationResponseReceived(true);
    }
  };

  // Create a function to render subfilters
  const renderSubFilters = () => {
    if (selectedMainFilter === '1') {
      return (
        <>
          <div className={styles.subFilters}>
            <input
              type="radio"
              id="filter3"
              className={styles.radioButton}
              name="subfilter"
              value="3"
              checked={selectedSubFilter === '3'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter3">Pending</label>

            <input
              type="radio"
              id="filter4"
              className={styles.radioButton}
              name="subfilter"
              value="4"
              checked={selectedSubFilter === '4'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter4">Sent</label>

            <input
              type="radio"
              id="filter5"
              className={styles.radioButton}
              name="subfilter"
              value="5"
              checked={selectedSubFilter === '5'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter5">Cancelled</label>

            <input
              type="radio"
              id="filter6"
              className={styles.radioButton}
              name="subfilter"
              value="6"
              checked={selectedSubFilter === '6'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter6">All</label>
          </div>
        </>
      );
    } else if (selectedMainFilter === '2') {
      return (
        <>
          <div className={styles.subFilters}>
            <input
              type="radio"
              id="filter7"
              className={styles.radioButton}
              name="subfilter"
              value="7"
              checked={selectedSubFilter === '7'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter7">Sent</label>

            <input
              type="radio"
              id="filter8"
              className={styles.radioButton}
              name="subfilter"
              value="8"
              checked={selectedSubFilter === '8'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter8">Cancelled</label>

            <input
              type="radio"
              id="filter9"
              className={styles.radioButton}
              name="subfilter"
              value="9"
              checked={selectedSubFilter === '9'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter9">All</label>
          </div>
        </>
      );
    }
  };

  const handleSubFilterChange = (event) => {
    setSelectedSubFilter(event.target.value);
  };

  const handleSort = () => {
    // Toggle sort order each time user clicks
    setSortOrder(!sortOrder);

    // Sort reminders
    setReminders((prevState) => {
      return prevState.sort((a, b) => {
        if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
          return sortOrder ? 1 : -1;
        }
        if (moment(b.createdAt).isBefore(moment(a.createdAt))) {
          return sortOrder ? -1 : 1;
        }
        return 0;
      });
    });
  };

  const handleCancelReminder = () => {
    // Determine if it's a multi-slot appointment
    const isMultiSlot = isMultiSlotAppointment(selectedReminder);

    // Setting the operation getting performed
    setOperationGettingPerformed('cancelAppointment');

    // Setting the Cancel Event Initial Steps
    setCurrentSocketIOSteps([
      ...Socket_IO_Appointment_Cancel_Event_Initial_Steps,
    ]);

    // Prepare the cancellation data
    let cancelData = {
      isMultiSlot: isMultiSlot, // Passing an extra parameter of isMultiSlot don't remove
      reminder: selectedReminder,
      updated_by: selectedCareCoordinator, // Who is making the update
      reason: reason, // Reason for cancellation
      openSlot: cancelOpenSlot, // Whether to open the slot
      appointment_id: selectedAppointmentId,
    };

    if (isMultiSlot) {
      // Multi-slot appointments: Cancel all slots in the series
      // If it's multiSlot then it will pick slot_ids
      cancelData.slot_ids = selectedSlots.map((slot) => slot.slot_id);
    } else {
      // Single-slot appointment: Only cancel the specific slot
      // If it's singleSlot then it will pick single slot_id
      cancelData.provider_availability_slots_id = selectedSlotId;
    }

    // Make the API call to cancel the appointment
    appointmentService.cancelAppointment(cancelData).then(
      (response) => {
        // Display success notification
        enqueueSnackbar('Appointment successfully cancelled', {
          variant: 'success',
        });
        // Received Response from BE
        setOperationResponseReceived(true);
      },
      (error) => {
        hideLoading(); // Hide loading indicator in case of error

        // Display error notification
        enqueueSnackbar('Failed to cancel appointment', { variant: 'error' });

        // Enable the cancel button again if there was an error
        setDisabledButton(false);

        // Received Response from BE
        setOperationResponseReceived(true);
      }
    );
  };

  const getSurveySentStatus = (row, slot) => {
    if (row.service_group !== 'Therapy') {
      return 'N/A';
    }

    if (slot?.survey_sent === null) {
      return 'N/A';
    }

    if (slot?.survey_sent === 1) {
      return 'Sent';
    }

    if (slot?.survey_cancelled === 1) {
      return 'Cancelled';
    }

    if (slot?.survey_sent === 0) {
      return (
        <span
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() => handleOpenCancelSurvey(row.provider_availability_id)} // Assuming row has provider_availability_id
        >
          Pending
        </span>
      );
    }
  };

  return (
    <div>
      {/**
       * Below Modal to gets appear when appointment is being cancelled or Rescheduled
       */}
      <SocketIoModal
        showSocketIOModal={showSocketIOModal}
        isMobile={isMobile}
        steps={steps} // Steps from the hook
        shouldDisableCloseButton={operationResponseReceived} // If we receive something from BE. Button will be enabled
        onClose={() => {
          if (operationGettingPerformed === 'cancelAppointment') {
            // Below hiding rescheduling modal
            setOpenSettingModal(false);
            // Setting Socket IO Steps back
            setCurrentSocketIOSteps([]);
            // Below opening the JotForm modal
            setShowFinalModal(true);
          } else if (operationGettingPerformed === 'updateAppointment') {
            // Below hiding rescheduling modal
            handleCloseSettingModal(); // Close the modal after success
            // Setting Socket IO Steps back
            setCurrentSocketIOSteps([]);
            // Reloading the page
            window.location.reload();
          }
        }}
      />

      {/**
       * Below Modal to show the JotForm at the end when the appointment was cancelled
       */}
      <CancellationFormModal
        showFinalModal={showFinalModal}
        setShowFinalModal={setShowFinalModal}
      />

      <div className={styles.searchField}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            className={styles.searchFieldInput}
            style={{ marginRight: '20px' }}
            helperText=" "
            id="demo-helper-text-aligned-no-helper"
            label="Search by Last Name"
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className={styles.filtersContainer}>
        <div
          className={styles.mainFilters}
          style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}
        >
          <input
            type="radio"
            id="filter1"
            className={`${styles.radioButton} ${styles.mainFiltersRadioButton}`}
            name="mainfilter"
            value="1"
            checked={selectedMainFilter === '1'}
            onChange={handleMainFilterChange}
          />
          <label htmlFor="filter1">Future Appts</label>
          <input
            type="radio"
            id="filter2"
            className={`${styles.radioButton} ${styles.mainFiltersRadioButton}`}
            name="mainfilter"
            value="2"
            checked={selectedMainFilter === '2'}
            onChange={handleMainFilterChange}
          />
          <label htmlFor="filter2">Past Appts</label>
        </div>

        <div className={styles.subFilters}>{renderSubFilters()}</div>
      </div>

      <TableContainer component={Paper} id="child">
        <Table
          sx={{ minWidth: 650 }}
          size="medium"
          aria-label="Reminders_table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Email Status</TableCell>
              <TableCell>Survey Sent</TableCell>
              <TableCell>Slot #</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <Tooltip
                title="Click to sort by Date/Time Created"
                placement="top"
              >
                <TableCell
                  onClick={() => handleSort()}
                  style={{ cursor: 'pointer' }}
                >
                  Date/Time Created{' '}
                  {sortOrder ? <ArrowDownward /> : <ArrowUpward />}
                </TableCell>
              </Tooltip>
              <TableCell>Care Coordinator</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>Appt Type</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>Service Type</TableCell>
              <TableCell>Issue</TableCell>
              <TableCell>Insurance</TableCell>
              <TableCell>Appt Date</TableCell>
              <TableCell>Appt Start Time</TableCell>
              <TableCell>Appt End Time</TableCell>
              <TableCell>
                {selectedMainFilter === '2'
                  ? 'Preview'
                  : 'Preview | Cancel/Reschedule'}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {reminders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                if (!row) return null; // Skip if row is undefined or null

                const isMultiSlot = isMultiSlotAppointment(row);
                const now = moment();

                // Determine background color for grouping
                const appointmentBackgroundColor =
                  index % 2 === 0 ? '#ffffff' : '#f9f9f9';

                const getEmailStatusBackGroundColor = (emailStatus) => {
                  if (emailStatus === 'pending') {
                    return '#fff9c4';
                  } else if (emailStatus === 'Sent') {
                    return 'lightgreen';
                  } else if (emailStatus === 'cancelled') {
                    return '#e85858';
                  }
                };

                // Add color logic for survey sent status
                const getSurveySentStatusData = (row, slot) => {
                  const surveySentStatus = getSurveySentStatus(row, slot);
                  if (surveySentStatus === 'Pending') {
                    return '#fff9c4'; // Light yellow
                  } else if (surveySentStatus === 'Sent') {
                    return 'lightgreen';
                  } else if (surveySentStatus === 'Cancelled') {
                    return '#e85858'; // Red
                  }
                };

                if (isMultiSlot) {
                  // Handle multi-slot appointments
                  const futureSlots = row.slots.filter((slot) =>
                    moment.utc(slot.start_date_time).isSameOrAfter(now)
                  );
                  const pastSlots = row.slots.filter((slot) =>
                    moment.utc(slot.start_date_time).isBefore(now)
                  );

                  futureSlots.sort((a, b) =>
                    moment
                      .utc(a.start_date_time)
                      .diff(moment(b.start_date_time))
                  );
                  pastSlots.sort((a, b) =>
                    moment
                      .utc(a.start_date_time)
                      .diff(moment(b.start_date_time))
                  );

                  const sortedSlots = [...futureSlots, ...pastSlots];
                  return (
                    <React.Fragment key={index}>
                      {sortedSlots.map((slot, slotIndex) => {
                        const slotNumber = slotIndex + 1;
                        const isPastSlot = moment(
                          slot.start_date_time
                        ).isBefore(now);
                        const rowBackgroundColor = isPastSlot
                          ? '#e0e0e0'
                          : appointmentBackgroundColor;

                        return (
                          <TableRow
                            key={`${index}-${slotIndex}`}
                            sx={{
                              backgroundColor: rowBackgroundColor,
                              '&:hover': { backgroundColor: '#f5f5f5' },
                              borderTop:
                                slotIndex === 0 ? '2px solid black' : undefined,
                              borderBottom:
                                slotIndex === sortedSlots.length - 1
                                  ? '2px solid black'
                                  : undefined,
                            }}
                          >
                            <TableCell
                              style={{
                                background: getEmailStatusBackGroundColor(
                                  slot?.email_status
                                ),
                              }}
                            >
                              {capitalizeFirstLetter(slot?.email_status)}
                            </TableCell>

                            <TableCell
                              style={{
                                background: getSurveySentStatusData(row, slot),
                              }}
                            >
                              {getSurveySentStatus(row, slot)}
                            </TableCell>

                            <TableCell>{`Slot ${slotNumber}`}</TableCell>

                            <TableCell>{row.first_name}</TableCell>

                            <TableCell>{row.last_name}</TableCell>
                            <TableCell>
                              {row.createdAt
                                ? moment(row.createdAt).format(
                                    'MM/DD/yyyy h:mm a'
                                  )
                                : 'N/A'}
                            </TableCell>

                            <TableCell>
                              {careCoordinators.find(
                                (coordinator) =>
                                  coordinator.id === row?.care_coordinator_id
                              )?.username ?? ''}{' '}
                            </TableCell>

                            <TableCell>{row.email}</TableCell>

                            <TableCell>
                              {row?.date_of_birth
                                ? moment(row?.date_of_birth).format(
                                    'MM/DD/yyyy'
                                  )
                                : 'N/A'}
                            </TableCell>

                            <TableCell>{row.appointment_type}</TableCell>

                            <TableCell>{row?.appointment_location}</TableCell>

                            <TableCell>
                              {row.provider ? row.provider.username : 'N/A'}
                            </TableCell>

                            <TableCell>{row.service_group}</TableCell>

                            {/**
                             * If there is no Service Selected. Then, it's
                             * Manually Created
                             */}
                            <TableCell>
                              {Array.isArray(row.services)
                                ? row.services
                                    .map((service) => service.title)
                                    .join(', ')
                                : 'N/A - Manually Created'}
                            </TableCell>

                            <TableCell>{row.insurance}</TableCell>

                            <TableCell>
                              {moment
                                .utc(slot.start_date_time)
                                .format('MM/DD/yyyy')}
                            </TableCell>

                            <TableCell>
                              {moment
                                .utc(slot.start_date_time)
                                .format('h:mm a')}
                            </TableCell>

                            <TableCell>
                              {moment.utc(slot.end_date_time).format('h:mm a')}
                            </TableCell>

                            {/* Action Buttons */}
                            <TableCell>
                              {/**
                               * The below is the eye icon for opening
                               */}
                              <RemoveRedEye
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  handleEyeClick(e, row, slot.id);
                                }}
                              ></RemoveRedEye>

                              {/*
                               * The below is the Pencil Icon for openning/editing
                               */}
                              {selectedMainFilter !== '2' && (
                                <Create
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setSelectedReminder(row);
                                    setSelectedAppointmentId(
                                      row.appointment_id
                                    );
                                    const clickedSlotId = slot.id;
                                    // For multi-slot, set all slots
                                    const sortedSlots = [...row.slots].sort(
                                      (a, b) =>
                                        moment(a.start_date_time).diff(
                                          moment(b.start_date_time)
                                        )
                                    );

                                    setSelectedSlots(
                                      sortedSlots.map((slot) => ({
                                        slot_id: slot.id,
                                        start_date_time: slot.start_date_time,
                                        end_date_time: slot.end_date_time,
                                        editable: slot.id === clickedSlotId, // Only the clicked slot is editable by default.
                                      }))
                                    );

                                    setReason('');
                                    setIsCancel(false);
                                    handleOpenSettingModal();
                                  }}
                                ></Create>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </React.Fragment>
                  );
                } else {
                  // Handle single-slot appointment case
                  const singleSlot = row.slots[0]; // Access the first element of slots array

                  // Single-slot appointment
                  const rowBackgroundColor =
                    index % 2 === 0 ? '#ffffff' : '#f9f9f9';

                  return (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: rowBackgroundColor,
                        '&:hover': { backgroundColor: '#f5f5f5' },
                      }}
                    >
                      <TableCell
                        style={{
                          background: getEmailStatusBackGroundColor(
                            singleSlot?.email_status
                          ),
                        }}
                      >
                        {capitalizeFirstLetter(singleSlot?.email_status)}
                      </TableCell>
                      <TableCell
                        style={{
                          background: getSurveySentStatusData(row, singleSlot),
                        }}
                      >
                        {getSurveySentStatus(row, singleSlot)}
                      </TableCell>
                      <TableCell>N/A</TableCell>
                      <TableCell>{row.first_name}</TableCell>
                      <TableCell>{row.last_name}</TableCell>
                      <TableCell>
                        {row.createdAt
                          ? moment(row.createdAt).format('MM/DD/yyyy h:mm a')
                          : 'N/A'}
                      </TableCell>

                      <TableCell>
                        {careCoordinators.find(
                          (coordinator) =>
                            coordinator.id === row?.care_coordinator_id
                        )?.username ?? ''}{' '}
                      </TableCell>

                      <TableCell>{row.email}</TableCell>

                      <TableCell>
                        {row?.date_of_birth
                          ? moment(row?.date_of_birth).format('MM/DD/yyyy')
                          : 'N/A'}
                      </TableCell>

                      <TableCell>{row.appointment_type}</TableCell>

                      <TableCell>{row?.appointment_location}</TableCell>

                      <TableCell>
                        {row.provider ? row.provider.username : 'N/A'}
                      </TableCell>
                      <TableCell>{row.service_group}</TableCell>

                      {/**
                       * If there is no Service Selected. Then, it's
                       * Manually Created
                       */}
                      <TableCell>
                        {Array.isArray(row.services)
                          ? row.services
                              .map((service) => service.title)
                              .join(', ')
                          : 'N/A - Manually Created'}
                      </TableCell>

                      <TableCell>{row.insurance}</TableCell>

                      <TableCell>
                        {row.appointment_start_time
                          ? moment
                              .utc(row.appointment_start_time)
                              .format('MM/DD/yyyy')
                          : 'N/A'}
                      </TableCell>

                      <TableCell>
                        {row.appointment_start_time
                          ? moment
                              .utc(row.appointment_start_time)
                              .format('h:mm a')
                          : 'N/A'}
                      </TableCell>

                      <TableCell>
                        {row.appointment_end_time
                          ? moment
                              .utc(row.appointment_end_time)
                              .format('h:mm a')
                          : 'N/A'}
                      </TableCell>

                      {/* Action Buttons */}
                      <TableCell>
                        <RemoveRedEye
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            handleEyeClick(e, row);
                          }}
                        ></RemoveRedEye>

                        {selectedMainFilter !== '2' && (
                          <Create
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedReminder(row);
                              setSelectedAppointmentId(row.appointment_id);

                              // Directly pass the appointment details for a single-slot appointment
                              setSelectedSlots([
                                {
                                  slot_id: row.provider_availability_slots_id,
                                  start_date_time: row.appointment_start_time,
                                  end_date_time: row.appointment_end_time,
                                  editable: true, // Editable by default for single slot, as there are no checkboxes involved.
                                },
                              ]);

                              setReason('');
                              setIsCancel(false);

                              // Setting the slot Id here
                              /**
                               * Function below is finding the exact Slot Id clicked
                               * Slot by comparing appointment date time
                               */
                              setSelectedSlotId(
                                findTheClickedSlot(
                                  row.appointment_start_time,
                                  row.appointment_end_time,
                                  row?.slots
                                )?.id
                              );
                              handleOpenSettingModal();
                            }}
                          ></Create>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={reminders.length}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) =>
          setRowsPerPage(parseInt(event.target.value, 25))
        }
        labelRowsPerPage="Rows per page:"
      />

      {/**
       * The below is the Modal for opening Reminder Preview when you
       * click on eye button on Reminder Preview
       */}
      <Modal
        open={openEmailReminder}
        onClose={handleCloseEmailReminder}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: 'scroll' }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '75%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 900,
            padding: 20,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reminder Preview
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            dangerouslySetInnerHTML={{ __html: reminderPreview }}
          ></Typography>
        </Box>
      </Modal>

      {/**
       * The below Modal opens up when cancelling/rescheduling MultiSlot Appointment or SingleSlot
       * Appointment as well.
       */}
      <MultiSlotModal
        open={openSettingModal}
        handleClose={handleCloseSettingModal}
        selectedSlots={selectedSlots}
        setSelectedSlots={setSelectedSlots}
        selectedReminder={selectedReminder}
        handleUpdateReminder={handleUpdateReminder}
        handleCancel={handleCancelReminder}
        reason={reason} // Pass reason state
        setReason={setReason} // Pass setReason function
        cancelOpenSlot={cancelOpenSlot} // Pass cancelOpenSlot state
        setCancelOpenSlot={setCancelOpenSlot} // Pass setCancelOpenSlot function
        adminOrCareCoordinators={adminOrCareCoordinators} // Pass this as a prop to MultiSlotModal
        onTagsChange={onTagsChange} // Pass the onTagsChange function as a prop
        disabledButton={disabledButton} // Pass the state
        setDisabledButton={setDisabledButton} // Pass the function
        isMultiSlotAppointment={isMultiSlotAppointment} // <-- Pass the function as a prop
      />

      {/* Modal/Dialog for Cancelling Survey */}
      <CancelSurveyDialog
        openCancelSurvey={openCancelSurvey}
        handleCloseCancelSurvey={handleCloseCancelSurvey}
        handleCombinedClick={handleCombinedClick}
        selectedProviderAvailabilityId={selectedProviderAvailabilityId}
      />
    </div>
  );
}
